import React from 'react'
import '../../static/scss/work.scss';

import video1 from '../../static/images/work/kamaayurveda/video1.mp4'
import secondimage from '../../static/images/work/kamaayurveda/secondimage.jpg'
import firstimage from '../../static/images/work/kamaayurveda/firstimage.jpg'


export default function Kama() {
  window.scrollTo(0, 0);

    return (
    <div className="workpage-inner grid">
        <div className="inner">
            <div className="head">
                <h3>Kama Ayurveda</h3>
                <p></p>

            </div>
            
            <div className="body">
                <div className="image-section img-21by9 w-100">
                    <video src={video1} controls></video>
                </div>
                <div className="image-section only-3-images">
                    <img src={firstimage} alt="" />
                </div>
                <div className="image-section only-3-images ">
                    <img src={secondimage} alt="" />
                </div>
            </div>
            <div className="footer">
                <h5>Work</h5>
                <p>DIGITAL CONTENT FILM</p>
            </div>
        </div>
    </div>
  )
}

