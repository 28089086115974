import React from 'react';
import { NavLink } from 'react-router-dom';
import '../static/scss/work.scss';

// Images
import fuel from '../static/images/work/fuel/secondimage.jpg'
import matturi from '../static/images/work/matturi/firstimage.jpg'
import oneplus from '../static/images/work/oneplus/firstimage.jpg'
import dotkey from '../static/images/work/dotkey/coverimage.jpg'
import kamaayurveda from '../static/images/work/kamaayurveda/coverimage.jpg'
import mestermor from '../static/images/work/mestermor/coverimage.jpg'
import ruby from '../static/images/work/ruby/firstimage.jpg'
import shirley from '../static/images/work/shirley/coverimage.jpg'
import superbottoms from '../static/images/work/superbottoms/fourthimage.jpg'
import tirumala from '../static/images/work/tirumala/coverimage.jpg'
import bsb from '../static/images/work/bsb/firstimage.jpg'
import foresthill from '../static/images/work/foresthill/firstimage.jpg'
import tint from '../static/images/work/tint/firstimage.jpg'
import manyavar from '../static/images/work/manyavar/coverimage.jpg'
import bind from '../static/images/work/bind/firstimage.jpg'


export default function Work() {
    return (
        <div className="parent">

            <NavLink to='fuel' className="div1">
                <div className="box-wrap">
                    <img src={fuel} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Fuel</h3>
                    </div>
                </div>
            </NavLink>
            <NavLink to='tint' className="div2">
                <div className="box-wrap">
                    <img src={tint} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Tint Cosmetics</h3>
                    </div>
                </div>
            </NavLink>
            <NavLink to='dotkey' className="div3">
                <div className="box-wrap">
                    <img src={dotkey} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Dot & Key Skincare</h3>
                    </div>
                </div>
            </NavLink>
            <NavLink to='shirley' className="div4">  
                <div className="box-wrap">
                    <img src={shirley} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Kama Ayurveda - Cleansing Foam</h3>
                    </div>
                </div>
            </NavLink>
            <NavLink to='ruby' className="div5">  
                <div className="box-wrap">
                    <img src={ruby} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Ruby's Organics</h3>
                    </div>
                </div>
            </NavLink>
            <NavLink to='bsb' className="div6">  
                <div className="box-wrap">
                    <img src={bsb} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">BrownSkin Beauty</h3>
                    </div>
                </div>
            </NavLink>
            <NavLink to='kamaayurveda' className="div7">  
                <div className="box-wrap">
                    <img src={kamaayurveda} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Kama Ayurveda</h3>
                    </div>
                </div>
            </NavLink>
            <NavLink to='superbottoms' className="div8">  
                <div className="box-wrap">
                <img src={superbottoms} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Super Bottoms</h3>
                    </div>
                </div>
            </NavLink>
            <NavLink to='foresthill' className="div9">  
                <div className="box-wrap">
                <img src={foresthill} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Forest Hill</h3>
                    </div>
                </div>
            </NavLink>
            <NavLink to='oneplus' className="div10"> 
                <div className="box-wrap">
                    <img src={oneplus} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Oneplus</h3>
                    </div>
                </div> 
            </NavLink>
            <NavLink to='matturi' className="div11"> 
                <div className="box-wrap">
                    <img src={matturi} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Matturi Fine Jewellery</h3>
                    </div>
                </div> 
            </NavLink>
            <NavLink to='tirumala' className="div12"> 
                <div className="box-wrap">
                    <img src={tirumala} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Tirumala</h3>
                    </div>
                </div> 
            </NavLink>
            <NavLink to='manyavar' className="div13"> 
                <div className="box-wrap">
                    <img src={manyavar} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Manyavar</h3>
                    </div>
                </div> 
            </NavLink>
            <NavLink to='mestermor' className="div14"> 
                <div className="box-wrap">
                    <img src={mestermor} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Mestermor</h3>
                    </div>
                </div> 
            </NavLink>
            <NavLink to='bind' className="div15"> 
                <div className="box-wrap">
                    <img src={bind} alt="" />
                    <div className="text-wrap">
                        <h3 className="text">Bind App</h3>
                    </div>
                </div> 
            </NavLink>
        </div>
    )
}
