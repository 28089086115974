import React from 'react'
import '../../static/scss/work.scss';

import firstimage from '../../static/images/work/dotkey/firstimage.MP4'
import secondimage from '../../static/images/work/dotkey/secondimage.jpg'
import thirdimage from '../../static/images/work/dotkey/thirdimage.jpg'
import fourthimage from '../../static/images/work/dotkey/fourthimage.jpg'

export default function DotKey() {
  window.scrollTo(0, 0);

    return (
    <div className="workpage-inner grid">
        <div className="inner">
            <div className="head">
                <h3>Dot & Key Skincare</h3>
                <p>Creative conceptualization, photography & production for the Dot & Key’s Newly Launched Haircare Range.</p>

            </div>
            <div className="body">
                <div className="image-section img-21by9">
                    <video src={firstimage} controls></video>
                </div>
                <div className="image-section">
                    <img src={secondimage} alt="" />
                </div>
                <div className="image-section">
                    <img src={thirdimage} alt="" />
                </div>
                <div className="image-section img-21by9">
                    <img src={fourthimage} alt="" />
                </div>
               
            </div>
            <div className="footer">
                <h5>Work</h5>
                <p>DIGITAL AD CONTENT</p>
            </div>
        </div>
    </div>
  )
}

