import React from 'react'
import '../../static/scss/work.scss';

import firstimage from '../../static/images/work/bind/firstimage.jpg'
import secondimage from '../../static/images/work/bind/secondimage.jpg'
import thirdimage from '../../static/images/work/bind/thirdimage.jpg'


export default function Bind() {
    window.scrollTo(0, 0);

    return (
        <div className="workpage-inner grid">
            <div className="inner">
                <div className="head">
                    <h3>Bind App</h3>
                    <p></p>

                </div>
                <div className="body">
                    <div className="image-section img-21by9">
                        <img src={firstimage} alt="" />
                    </div>
                    <div className="image-section only-3-images">
                        <img src={secondimage} alt="" />
                    </div>
                    <div className="image-section only-3-images">
                        <img src={thirdimage} alt="" />
                    </div>


                </div>
                <div className="footer">
                    <h5>Work</h5>
                    <p>SOCIAL MEDIA & DIGITAL STRATEGY</p>
                </div>
            </div>
        </div>
    )
}

