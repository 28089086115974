import React from 'react'
import '../static/scss/work.scss';
import video1 from '../static/video/eeladikama/eladikama.mp4'


export default function Eeladikama() {
  return (
    <div className="workpage-inner grid">
        <div className="inner">
            <div className="head">
                <h3>Eeladikama</h3>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quisquam excepturi in magni voluptatum perferendis hic totam nobis asperiores perspiciatis eveniet.</p>

            </div>
            <div className="body">
                <div className="image-section img-21by9">
                    <video src={video1} controls></video>
                </div>
               
               
            </div>
            <div className="footer">
                <h5>Work</h5>
                <p>Lorem, ipsum dolor.</p>
            </div>
        </div>
    </div>
  )
}

