import React from "react";
import About from "./components/About";
import Clients from "./components/Clients";
import Contact from "./components/Contact";
import Home from "./components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Service from "./components/Service";
import './static/scss/common.scss';
import Blog from "./components/Blog";
import Blog4 from "./components/Blog4";
import Blog3 from "./components/Blog3";
import Blog2 from "./components/Blog2";
import Work from "./components/Work";
import Work1 from "./components/Work1";
import Work3 from "./components/Work3";
import Work2 from "./components/Work2";
import Verticle from "./components/Verticle";
import Verticle2 from "./components/Verticle2";
import Verticle3 from "./components/Verticle3";
import Fuel from "./components/work/Fuel";
import Matturi from "./components/work/Matturi";
import Foresthill from "./components/work/Foresthill";
import Oneplus from "./components/work/Oneplus";
import DotKey from "./components/work/DotKey";
import Eeladikama from "./components/Eeladikama";
import Mestermor from "./components/work/Mestermor";
import Ruby from "./components/work/Ruby";
import Tint from "./components/work/Tint";
import Superbottoms from "./components/work/Superbottoms";
import Shirley from "./components/work/Shirley";
import Tirumala from "./components/work/Tirumala";
import Bind from "./components/work/Bind";
import Tesora from "./components/Tesora";
import Bsb from "./components/work/Bsb";
import Manyavar from "./components/work/Manyavar";
import Kama from "./components/work/Kama";
function App() {
  return (
    <>
      <BrowserRouter >
        <Navbar />

        <Routes>
          <Route path="/">

            <Route path="/" element={<Home />} />
            <Route path="/about"  element={<About />} />
            <Route path="/clients"  element={<Clients />} />
            <Route path="/service"  element={<Service />} />
            <Route path="/work"  element={<Work />} />
            <Route path="/contact"  element={<Contact />} />
            <Route path="/influence"  element={<Blog />} />
            <Route path="/content-creation"  element={<Blog2 />} />
            <Route path="/digital-strategy"  element={<Blog3 />} />
            <Route path="/branding-design"  element={<Blog4 />} />


            <Route path="/work/fuel"  element={<Fuel />} />
            <Route path="/work/matturi"  element={<Matturi />} />
            <Route path="/work/foresthill"  element={<Foresthill />} />
            <Route path="/work/oneplus"  element={<Oneplus />} />

            
            <Route path="/work/dotkey"  element={<DotKey />} />
            <Route path="/work/eladikama"  element={<Eeladikama />} />
            <Route path="/work/mestermor"  element={<Mestermor />} />
            <Route path="/work/ruby"  element={<Ruby />} />
            <Route path="/work/bsb"  element={<Bsb />} />
            <Route path="/work/tint"  element={<Tint />} />
            <Route path="/work/shirley"  element={<Shirley />} />
            <Route path="/work/superbottoms"  element={<Superbottoms />} />
            <Route path="/work/tirumala"  element={<Tirumala />} />
            <Route path="/work/tesora"  element={<Tesora />} />
            <Route path="/work/bind"  element={<Bind />} />
            <Route path="/work/manyavar"  element={<Manyavar />} />
            <Route path="/work/kamaayurveda"  element={<Kama />} />

            
            
            
            <Route path="/work/work-inner-2"  element={<Work2 />} />
            <Route path="/work/work-inner-3"  element={<Work3 />} />
            <Route path="/work/vertical-layout"  element={<Verticle />} />
            <Route path="/work/vertical-layout2"  element={<Verticle2 />} />
            <Route path="/work/vertical-layout3"  element={<Verticle3 />} />





            <Route path="/work-inner-1"  element={<Work1 />} />
            <Route path="/work-inner-2"  element={<Work2 />} />
            <Route path="/work-inner-3"  element={<Work3 />} />
            <Route path="/verticle-layout"  element={<Verticle />} />
            <Route path="/verticle-layout2"  element={<Verticle2 />} />
       
          </Route>


        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
