import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import logo from '../static/images/logo.png'
import '../static/scss/navbar.scss'


export default function Navbar() {
    let currenturl = window.location.pathname;

    const [first, setfirst] = useState(true)
    let a = () => {
        setfirst(first ? false : true)
    }
    const [hamburger, sethamburger] = useState(false);
    let hamburgerNav = (val) => {
        sethamburger(hamburger ? false : true)
    }


    return (
        <nav id="navbar" className={`navbar ${currenturl !== '/' ? 'brown' : ''} ${currenturl === '/about' ? 'aboutNav' : ''} ${currenturl === '/clients' ? 'clientsNav' : ''} `}>
            <div className="logo">
                <NavLink to='/' onClick={() => {
                    a()
                }} >
                    <img src={logo} alt="" className={currenturl !== '/' ? 'logo-filter-other-pages' : 'logo-filter-home-page'} />
                    {/* <img src={logo} alt="" className='' /> */}
                </NavLink>
            </div>
            <div className="text">
                <NavLink to="/" onClick={() => {
                    a()


                }}>
                    <h2>THE SINGLE DESK</h2>
                </NavLink>
            </div>
            <div className={hamburger ? "hamburger active" : "hamburger"} onClick={() => {
                hamburgerNav()
            }}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <ul className={hamburger ? 'toggle-state active' : 'toggle-state'}>
                <li>
                    <NavLink to="/" onClick={() => {
                        hamburgerNav()
                    }} >
                        <span className="material-symbols-outlined">
                            home
                        </span>
                    </NavLink>
                </li>
                <li><NavLink to="about" onClick={() => {
                    hamburgerNav()
                }}>About</NavLink></li>
                <li><NavLink to="service" onClick={() => {
                    hamburgerNav()

                }}>Services</NavLink></li>
                <li><NavLink to="work" onClick={() => {
                    hamburgerNav()
                }}>Work</NavLink></li>
                <li><NavLink to="clients" onClick={() => {
                    hamburgerNav()
                }}>Our Partners</NavLink></li>
                <li><NavLink to="contact" onClick={() => {
                    hamburgerNav()
                }}>Contact Us</NavLink></li>
                <div className="hamburger-close" onClick={() => {
                    hamburgerNav()
                }}>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </ul>
        </nav>
    )

}

// // When the user scrolls the page, execute myFunction
// window.onscroll = function() {myFunction()};

// // Get the navbar
// var navbar = document.getElementById("navbar");

// // Get the offset position of the navbar
// var sticky = 0;

// // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
// function myFunction() {
//   if (window.pageYOffset !== sticky) {
//     navbar.classList.add("sticky")
//   } else {
//     navbar.classList.remove("sticky");
//   }
// }