import React from 'react'
import '../static/scss/verticle.scss'
import img1 from '../static/images/verticle-image/shirley-1.jpeg'
import img2 from '../static/images/verticle-image/shirley-2.jpeg'
import video1 from '../static/video/video1.mp4'
// import video2 from '../static/video/shirly.mp4'

export default function Verticle() {
  return (
    <div className="verticle-image">
      <div className="item item1">
        <img src={img1} alt="" />
      </div>
      <div className="item item2">
        <img src={img2} alt="" />
      </div>
      <div className="item item3">
        <video src={video1} controls></video>
      </div>
        
      <div className="item item4">
        <video src={video1} controls></video>
      </div>
        
      <div className="item item1">
        <img src={img1} alt="" />
      </div>
      <div className="item item2">
        <img src={img2} alt="" />
      </div>
      <div className="item item3">
        <video src={video1} controls></video>
      </div>
        
      <div className="item item4">
        <video src={video1} controls></video>
      </div>
        
      <div className="item item1">
        <img src={img1} alt="" />
      </div>
      <div className="item item2">
        <img src={img2} alt="" />
      </div>
      <div className="item item3">
        <video src={video1} controls></video>
      </div>
        
      <div className="item item4">
        <video src={video1} controls></video>
      </div>
        
      <div className="item item1">
        <img src={img1} alt="" />
      </div>
      <div className="item item2">
        <img src={img2} alt="" />
      </div>
     
        
    </div>
  )
}
