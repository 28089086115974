import React, { useRef, useState } from 'react'
import contact from '../static/images/contact.jpg';
// import $ from 'jquery'
import '../static/scss/contact.scss'
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';




export default function Contact() {
    const form = useRef();

    const intialValues = { name: '', email: '', message: '' }
    const [formValues, setFormValues] = useState(intialValues)
    const handleFormValues = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })
    }

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(`${process.env.REACT_APP_EMAILJS_SERVICE_ID}`, `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}`, form.current, `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY}`)
            .then((result) => {
                console.log(result);
                if (result.status === 200) {

                    setFormValues(intialValues)
                    document.getElementById('mail-status').innerHTML = "<p>Mail sent, Thanks for contacting us</p>"
                    setTimeout(() => {

                        document.getElementById('mail-status').innerHTML = ""
                    }, 10000);
                }
                else {
                    document.getElementById('mail-status').innerHTML = "<p>something got wrong</p>"

                }
            }, (error) => {
                console.log(error.text);
                document.getElementById('mail-status').innerHTML = "<p>something got wrong</p>"
            });
    };
    return (
        <div className="contact">
            <div className="inner">
                <div className="form">
                    <img src={contact} alt="" />

                    <h3>Contact Us</h3>
                    <h4>Feel free to contact us anytime.
                        We will get back to you soon</h4>
                    <form ref={form} onSubmit={sendEmail} >
                        <div id="mail-status">
                        </div>
                        <div className='form-element'>
                            <input type="text" placeholder='Name' name='name' id='fname' value={formValues.name} onChange={handleFormValues} />
                        </div>
                        <div className='form-element'>
                            <input type="text" placeholder='Email' name='email' id='email' required value={formValues.email} onChange={handleFormValues} />
                        </div>
                        <div className='form-element' >
                            <input type="text" placeholder='Message' name='message' id='message' value={formValues.message} onChange={handleFormValues} />
                        </div>
                        <button type='submit' name='submit'>
                            Submit
                        </button>
                    </form>
                    <div className="content ">
                        <div className="mail">
                            <FontAwesomeIcon icon={faEnvelope} />

                            <a href="mailto:hello@thesingledesk.com">
                                <span className='text'>
                                    hello@thesingledesk.com
                                </span>
                            </a>
                        </div>

                        <div className="social">
                            <span className="text bold">
                                Connect with us on:
                            </span>
                            <div className='social'>

                                <a href="https://www.instagram.com/thesingledesk/?hl=en" target="_blank" rel='noreferrer'>
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                                <a href="https://www.linkedin.com/company/the-single-desk/mycompany/" target="_blank" rel='noreferrer'>
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="contact-imageside">
                    <img src={contact} alt="" />
                    <div className="content ">
                        <div className="mail">
                            <FontAwesomeIcon icon={faEnvelope} />

                            <a href="mailto:hello@thesingledesk.com">
                                <span className='text'>
                                    hello@thesingledesk.com
                                </span>
                            </a>
                        </div>

                        <div className="social">
                            <span className="text bold">
                                Connect with us on:
                            </span>
                            <div className='social'>
                                <a href="https://www.instagram.com/thesingledesk/?hl=en" target="_blank" rel='noreferrer'>
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                                <a href="https://www.linkedin.com/company/the-single-desk/mycompany/" target="_blank" rel='noreferrer'>
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    )
}
