import React from 'react'
import '../static/scss/home.scss'

import { Scrollbar, Mousewheel, Autoplay  } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/mousewheel';


export default function Home() {
    
    return (
        <>
            <Swiper 
            modules={[Scrollbar,Mousewheel,Autoplay]} 
            scrollbar={{ draggable: true }}
                slidesPerView={1} 
                // direction={'horizontal'} 
                autoplay={{
                    delay:4000,

                }}
                mousewheel={true}
                className="home-slider">

                <SwiperSlide className="home">
                    <h2>The one desk army of the digital era</h2>
                </SwiperSlide>
                <SwiperSlide className="home home2">
                    <h2>
                        We are a creative agency in the <br />
                        business of building brands.<br /> <br />
                        We lead with purpose <br />
                        We strike with precision <br />
                        We create with love <br />
                    </h2>
                </SwiperSlide>
            </Swiper>
        </>
    )
}
