import React from 'react'
import '../static/scss/about.scss'
import disha from '../static/images/disha.png'

export default function About() {
  return (
    <>
      <div className="about">
        <div className="about-wrap">
          <div className="about-top">
            <div className="inner">

              <h2>About Us</h2>
              <p>
                Started from a single desk in 2020 in Disha’s room, the agency was born to creatively solve brand problems with strategy and soul. Over the years, our team, clients, and drive to empower brands with cutting-edge tools in the digital world have only grown. What began as a dream; manifested into an influencer-marketing, content-producing, brand-strategising powerhouse.
              </p>
              <p>
                Whether you’re an individual or a brand looking to make waves across the industry, we can always clear up some space for you at our desk.
              </p>
            </div>
          </div>
          <div className="about-bottom">
            <div className="inner">

              <h2>Disha Shah</h2>
              <h5>Founder</h5>
              <p>
                Disha has spent nearly 10 years in the marketing industry. With a background in finance, she is able to view the world of brands through a unique lens. On collaborating with clients to prioritise business objectives, she transforms insight into strategy.
              </p>
              <p>
                She has worked on a repertoire of brands including Manyavar, Birdhichand, Anthony Murray, Forever 21, and more. She has a knack for looking at the bigger picture while ironing out the smallest details.
              </p>
              <div className="photo">
                <img src={disha} alt="" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
