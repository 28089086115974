import React from 'react'
import '../../static/scss/work.scss';

import firstimage from '../../static/images/work/tirumala/firstimage.jpg'
import secondimage from '../../static/images/work/tirumala/secondimage.jpg'
import thirdimage from '../../static/images/work/tirumala/thirdimage.jpg'
import fourthimage from '../../static/images/work/tirumala/fourthimage.jpg'

export default function Tirumala() {
  window.scrollTo(0, 0);

    return (
    <div className="workpage-inner grid">
        <div className="inner">
            <div className="head">
                <h3>Tirumala</h3>
                <p></p>

            </div>
            <div className="body">
                <div className="image-section img-21by9">
                    <img src={firstimage} alt="" />
                </div>
                <div className="image-section">
                    <img src={secondimage} alt="" />
                </div>
                <div className="image-section">
                    <img src={thirdimage} alt="" />
                </div>
                <div className="image-section img-21by9">
                    <img src={fourthimage} alt="" />
                </div>
               
            </div>
            <div className="footer">
                <h5>Work</h5>
                <p>CREATIVE CONTENT SHOOT</p>
            </div>
        </div>
    </div>
  )
}

