import React from 'react'
import '../../static/scss/work.scss';

import firstimage from '../../static/images/work/mestermor/firstimage.jpg'
import secondimage from '../../static/images/work/mestermor/secondimage.jpg'
import thirdimage from '../../static/images/work/mestermor/thirdimage.jpg'


export default function Mestermor() {
  window.scrollTo(0, 0);

    return (
    <div className="workpage-inner grid">
        <div className="inner">
            <div className="head">
                <h3>Mestermor</h3>
                <p></p>

            </div>
            <div className="body">
                <div className="image-section img-21by9">
                    <img src={firstimage} alt="" />
                </div>
                <div className="image-section only-3-images">
                    <img src={secondimage} alt="" />
                </div>
                <div className="image-section only-3-images">
                    <img src={thirdimage} alt="" />
                </div>
                
            </div>
            <div className="footer">
                <h5>Work</h5>
                <p>SOCIAL MEDIA & DIGITAL STRATEGY</p>
            </div>
        </div>
    </div>
  )
}

