import React from 'react'
import '../static/scss/work.scss';

// Images
import firstimage from '../static/images/work/matturi/firstimage.jpg'
import secondimage from '../static/images/work/matturi/secondimage.jpg'

export default function Work2() {
  return (
    <div className="workpage-inner">
        <div className="inner">
            <div className="head">
                <h3>Matturi</h3>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quisquam excepturi in magni voluptatum perferendis hic totam nobis asperiores perspiciatis eveniet.</p>

            </div>
            <div className="body workinner">
                <div className="image-section">
                    <img src={firstimage} alt="" />
                </div>
                <div className="image-section">
                    <img src={secondimage} alt="" />
                </div>
               
            </div>
            <div className="footer">
                <h5>Work</h5>
                <p>Lorem, ipsum dolor.</p>
            </div>
        </div>
    </div>
  )
}

