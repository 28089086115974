import React from 'react'
// import { NavLink } from 'react-router-dom'   
import '../static/scss/service.scss'

export default function Service() {
    return (
        <>
            <div className="service">
                <div className="inner service-wrap">
                    <div className="service-individual">
                        {/* <NavLink to='/influence' > */}
                        <h3>01</h3>

                        <h5>Influencer <br /> Marketing</h5>
                        <p>
                            We identify, negotiate
                            with, supervise content
                            production, and share
                            detailed timely reports
                            on influencers while
                            being guided by the
                            brand's ethos at
                            every step.
                        </p>
                        {/* </NavLink> */}
                    </div>
                    <div className="service-individual">
                        {/* <NavLink to='/content-creation' > */}

                        <h3>02</h3>
                        <h5>Content <br /> Creation</h5>
                        <p>
                            We bring together
                            creatives, technicians,
                            and logistics to
                            conceptualise, shoot,
                            and supervise each of
                            our campaigns.
                        </p>
                        {/* </NavLink> */}
                    </div>
                    <div className="service-individual">
                        {/* <NavLink to='/digital-strategy' > */}


                        <h3>03</h3>
                        <h5>Digital <br /> Strategy</h5>
                        <p>
                            We strategise, write, and
                            design, to meet all of our
                            brands' social media
                            needs, helping them
                            connect with audiences
                            right where they are.
                        </p>
                        
                        {/* </NavLink> */}
                    </div>
                    <div className="service-individual">
                        {/* <NavLink to='/branding-design' > */}


                        <h3>04</h3>
                        <h5>Branding & <br />
                            UI/UX Design</h5>
                        <p>
                            With the right UI/UX
                            support, tech input, and
                            optimisation, we are able
                            to extend the personality
                            of our brands to the
                            digital space.
                        </p>
                        {/* </NavLink> */}
                    </div>
                </div>
            </div>
        </>
    )
}
