import React from 'react'
import '../../static/scss/work.scss';

import firstimage from '../../static/images/work/ruby/firstimage.jpg'
import video1 from '../../static/images/work/ruby/video1.mp4'
import video2 from '../../static/images/work/ruby/video2.mp4'


export default function Ruby() {
  window.scrollTo(0, 0);

    return (
    <div className="workpage-inner grid">
    <div className="inner">
        <div className="head">
            <h3>Ruby's Organics</h3>
            <p></p>

        </div>
        <div className="body">
            <div className="image-section img-21by9">
                <img src={firstimage} alt="" />
            </div>
            <div className="image-section only-3-images">
                <video src={video1} controls></video>
            </div>
            <div className="image-section only-3-images">
            <video src={video2} controls></video>
            </div>
            
        </div>
        <div className="footer">
            <h5>Work</h5>
            <p>SOCIAL MEDIA & DIGITAL STRATEGY</p>
        </div>
    </div>
</div>
    
  )
}

