import React from 'react'
import '../static/scss/verticle.scss'
import img1 from '../static/images/verticle-image/shirley-1.jpeg'
import img2 from '../static/images/verticle-image/shirley-2.jpeg'
// import video1 from '../static/video/shirly.mp4'
// import video2 from '../static/video/shirly.mp4'

export default function Verticle2() {
  return (
    <div className="verticle-image verticle-2">
        {/* pattern for markup  */}
        {/* verticle verticle horizontal */}
      <div className="item verticle">
        <img src={img1} alt="" />
      </div>
      <div className="item verticle">
        <img src={img2} alt="" />
      </div>
      <div className="item verticle">
        <img src={img1} alt="" />
      </div>             
    </div>
  )
}
