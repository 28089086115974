import React from 'react'
import '../static/scss/work.scss';

import firstimage from '../static/images/work/foresthill/firstimage.jpg'
import secondimage from '../static/images/work/foresthill/secondimage.jpg'
import thirdimage from '../static/images/work/foresthill/thirdimage.jpg'
import fourthimage from '../static/images/work/foresthill/fourthimage.jpg'
import fifthimage from '../static/images/work/foresthill/firstimage.jpg'
import sixthimage from '../static/images/work/foresthill/firstimage.jpg'

export default function Tesora() {
  return (
    <div className="workpage-inner grid">
        <div className="inner">
            <div className="head">
            <h3>Tesora</h3>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quisquam excepturi in magni voluptatum perferendis hic totam nobis asperiores perspiciatis eveniet.</p>

            </div>
            <div className="body">
                <div className="image-section img-21by9">
                    <img src={firstimage} alt="" />
                </div>
                <div className="image-section">
                    <img src={secondimage} alt="" />
                </div>
                <div className="image-section">
                    <img src={thirdimage} alt="" />
                </div>
                <div className="image-section">
                    <img src={fourthimage} alt="" />
                </div>
                <div className="image-section">
                    <img src={fifthimage} alt="" />
                </div>
                <div className="image-section img-21by9">
                    <img src={sixthimage} alt="" />
                </div>
               
            </div>
            <div className="footer">
                <h5>Work</h5>
                <p>Lorem, ipsum dolor.</p>
            </div>
        </div>
    </div>
  )
}

