import React from 'react'
import '../../static/scss/work.scss';

// Images
import firstimage from '../../static/images/work/fuel/firstimage.jpg'
import secondimage from '../../static/images/work/fuel/secondimage.jpg'
import thirdimage from '../../static/images/work/fuel/thirdimage.jpg'

export default function Fuel() {
  window.scrollTo(0, 0);

    return (
    <div className="workpage-inner fuel">
        <div className="inner">
            <div className="head">
                <h3>Fuel</h3>
                <p>Fuel is a multi designer store based in Mumbai which had it’s Black Friday Sale. Here are some creatives & the campaign execution which drove optimum traction to their page increasing their overall sales. </p>

            </div>
            <div className="body workinner">
                <div className="image-section">
                    <img src={secondimage} alt="" />
                </div>
                <div className="image-section">
                    <img src={thirdimage} alt="" />
                </div>
                <div className="image-section">
                    <img src={firstimage} alt="" />
                </div>
            </div>
            <div className="footer">
                <h5>Work</h5>
                <p>SOCIAL MEDIA & INFLUENCER MARKETING</p>
            </div>
        </div>
    </div>
  )
}

