import React from 'react'
import client from '../static/images/clientmobile.png'
import clidesk from '../static/images/client1.png'
import 'swiper/scss';
import 'swiper/scss/grid';
import 'swiper/scss/pagination';

import '../static/scss/clients.scss'

export default function Clients() {

    return (
        <div className="client">
            <div className="inner client-wrapper">
            <h3>Our Partners</h3>
        
               <img src={clidesk} className="desktop" alt="" />
               <img src={client} className="mobile" alt="" />
            </div>
        </div>
    )
}
