import React from 'react'
import '../../static/scss/work.scss';

// Images
import firstimage from '../../static/images/work/matturi/firstimage.jpg'
import secondimage from '../../static/images/work/matturi/secondimage.jpg'
import thirdimage from '../../static/images/work/matturi/thirdimage.jpg'

export default function Matturi() {
  window.scrollTo(0, 0);

    return (
    <div className="workpage-inner grid">
        <div className="inner">
            <div className="head">
                <h3>Matturi Fine Jewellery</h3>
                <p></p>

            </div>
            <div className="body">
                <div className="image-section only-3-images img-21by9 w-100">
                    <img src={firstimage} alt="" />
                </div>
                <div className="image-section only-3-images">
                    <img src={secondimage} alt="" />
                </div>
                <div className="image-section only-3-images">
                    <img src={thirdimage} alt="" />
                </div>
            </div>
            <div className="footer">
                <h5>Work</h5>
                <p>WEBSITE OPTIMISATION</p>
            </div>
        </div>
    </div>
  )
}

