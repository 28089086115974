import React from 'react'
import '../../static/scss/verticle.scss'
import video1 from '../../static/video/oneplus/video1.mp4'
import video2 from '../../static/video/oneplus/video2.mp4'
import video3 from '../../static/video/oneplus/video3.mp4'
import cover1 from '../../static/video/oneplus/cover1.jpg'
import karishma from '../../static/video/oneplus/karishma.jpg'
import sanjana from '../../static/video/oneplus/sanjana.jpg'


export default function Oneplus() {
    window.scrollTo(0, 0);

    return (
        <div className="workpage-inner grid ">
        <div className="inner">
            <div className="head">
                <h3>Oneplus</h3>
                <p>We executed a reel campaign for the One Plus Nord 2T powered by Mediatek Dimensity 1300, with Karishma Tanna, Sanjana Sanghi & Amyra Dastur . Scripted, conceptualised & executed by The Single Desk Studio.</p>

            </div>
            <div className="verticle-image oneplus">
                <div className="image-section img-21by9">
                <video src={video1} controls muted poster={cover1}></video>
                </div>
                <div className="image-section">
                <video src={video2} controls muted poster={karishma}></video>
                </div>
               
                <div className="image-section ">
                <video src={video3} controls muted poster={sanjana}></video>
                </div>      
               
            </div>
            <div className="footer">
                <h5>Work</h5>
                <p>CONTENT PRODUCTION </p>
            </div>
        </div>
    </div>
    )
}

