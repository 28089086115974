import React from 'react'
import '../../static/scss/verticle.scss'
import '../../static/scss/work.scss';

import firstimage from '../../static/images/work/shirley/firstimage.jpg'
import video1 from '../../static/images/work/shirley/video1.m4v'

export default function Shirley() {
  window.scrollTo(0, 0);

    return (
    <div className="workpage-inner">
        <div className="inner">
            <div className="head">
                <h3>Kama Ayurveda - Cleansing Foam</h3>
                <p></p>

            </div>
            <div className="body workinner">
                <div className="image-section">
                    <video src={video1} controls></video>
                </div>
                <div className="image-section">
                    <img src={firstimage} alt="" />
                </div>
               
            </div>
            <div className="footer">
                <h5>Work</h5>
                <p>DIGITAL FILM</p>
            </div>
        </div>
    </div>
  )
}

